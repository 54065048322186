#login-container
  margin: 15vh 0 0 0
  .logo
    margin: 20px 0 20px 0
    max-width: 230px

  .login
    margin: auto
    max-width: 400px
    form
      margin: 0 10px

  .forget-password
    color: $white
    text-decoration: underline

#login-footer
  position: fixed
  width: 100%
  bottom: 10px

#password-requirements
  display: none
  margin-top: 10px
  margin-left: 10px
  p
    margin-bottom: 5px
  ul
    padding-left: 20px
    li
      &.error
        color: $redErros
      &.success
        color: $success

#password-2-requirements
  display: none
  margin-top: 10px
  margin-left: 10px
  p
    margin-bottom: 5px
    &.error
      color: $redErros

// mobile styles
@media (max-width: 767px)
  #login-container
    margin: 8vh 0 0 0
