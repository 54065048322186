.bootstrap-select
  height: 31px
  font-family: 'Source Sans Pro',sans-serif !important
  .dropdown-menu
      margin-top: 0
  &.bootstrap-select-sm
    button
      font-size: 12px !important
    .dropdown-menu
      font-size: 12px !important
