.request-list
  font-size: 12px

  .request
    border-bottom: 1px solid #9c9c9c4d
    margin: 6px 0 0 0
    min-width: 1000px
    div
      display: flex
      align-items: center
      height: 45px
      &.center
        justify-content: flex-start
    .status-circle
      &-red 
        color: #cf2a27
        margin-right: 5px
    .chevron
      display: flex
      justify-content: flex-end
      padding: 0 15px

  .request-header
    border: 1px solid #F4F4F4
    background: #F9F9F9
    min-width: 1000px
    width: 100%
    margin: 0px
    div
      display: flex
      align-items: center
      height: 30px
      padding: 0 16px
      &.center
        justify-content: flex-start
    .status-circle
      &-red
        color: #cf2a27
        margin-right: 5px
    .chevron
      display: flex
      justify-content: flex-end
      padding: 0 15px

  .table-request
    width: 100%
    max-width: 100%
    min-width: 1000px
    transition: max-height 1s
    //transform-origin: top
    //transition: transform 0.5s ease
    table
      .material-icons
        font-size: 14px
        margin-top: 2px
      thead
        border-top: none
        border-bottom: none
        tr
          border: none
          th
            border: none
            padding: 4px
            font-size: 9px
            font-weight: normal
      tbody
        border-top: none
        td
          padding: 7px 4px
          font-size: 12px

.request-detail
  .row
    margin: 0
  .table
    margin-bottom: 0
  .summary
    margin-top: 15px !important
    font-size: 12px
    background-color: #f9f9f9
    margin: 0
    padding: 15px 0
  .activity-comments
    padding: 10px
    margin: 0 20px
    .comment
      textarea
        resize: none
        width: 80%
        height: 60px
        margin-bottom: 10px
      border-bottom: 1px solid #e4e1e1
    .comments
      padding: 10px 0


.create-detail
  border: 1px solid #9c9c9c4d
  min-width: 100%
  min-height: 300px
  max-height: 450px
  overflow-y: auto
  padding: 5px
  font-size: 11px
  .material-icons
    font-size: 14px
    margin-top: 2px
  table
    tbody
      border: 1px solid #9c9c9c4d
      td
        padding: 5px
        border-top: 1px solid #9c9c9c4d

.create-request
  h3
    margin: 5px 0 15px 0
    font-size: 20px
  .material-icons
    font-size: 13px
  .checkbox
    label
      color: #333
  .add-cars
    display: flex
    flex-wrap: wrap
    .arrow
      display: flex
      align-items: center
      padding: 5px 15px 5px 0
    .detail
      display: flex
      flex: 1
      flex-direction: column
      //.form-horizontal
      //  margin-top: 10px


.flex-wrap
  display: flex
  justify-content: space-between
  flex-wrap: wrap
  &-item
    flex-grow: 1
  &-item-center
    display: flex
    flex-grow: 1
    justify-content: center

.bg-request-title
  background-color: #f5f5f5

.head-sorted
  i
    position: absolute
    right: 0
    margin-left: 5px

.container-table-add-car
  padding: 6px
  border: 1px solid #f2f2f2
  background: #f9f9f9
  margin-top: 15px
