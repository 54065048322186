#form-detail
  h4
    color: #2776b8
    margin-bottom: 0
    &:first-child
      margin-top: 20px
  .section
    margin-bottom: 30px
    .question
      border-bottom: 1px solid #f1f1f1
      padding: 15px 0
      .btn-group-justified
        .btn-group
          &:last-child
            .btn
              border-right-width: 1px
          .btn
            border-right-width: 0
      .images
        margin-bottom: 5px
        margin-top: 20px
        img
          image-orientation: from-image
          height: 100px
          width: 100px
          object-fit: cover
          padding: 5px
  .damage
    margin-bottom: 10px
    &:last-child
      margin-bottom: 0
    .damage-detail
      margin: 5px 10px
      background: #f9f9f9
      border: 1px solid #f1f1f1
      .damage-description
        padding: 5px 4px
        border-bottom: 1px solid #f1f1f1
      //.images
      //  img
      //    transition: all .25s linear
      //    &:hover
      //      transform: scale(1.1,1.1)
      margin-bottom: 5px
      padding: 5px
      .title
        color: #2776b8
      .images
        margin-top: 0 !important
      .tooltip
        min-width: 200px
        max-width: 100%
        overflow-wrap: break-word
  .special-question
    margin-left: -22px
    &.images
      margin-bottom: 5px
      img
        image-orientation: from-image
        height: 50px
        width: 50px
        object-fit: cover
        padding: 5px
