.transmittal-list
  font-size: 12px

  .transmittal
    border-bottom: 1px solid #9c9c9c4d
    margin: 6px 0 0 0
    min-width: 1000px
    .flex-45
      display: flex
      align-items: center
      height: 45px
      padding: 0 7px
      &.center
        justify-content: center
    .status-circle
      &-red
        color: #cf2a27
        margin-right: 5px
    .chevron
      display: flex
      justify-content: flex-end
      padding: 0 15px

  .table-transmittal
    width: 100%
    max-width: 100%
    min-width: 1000px
    transition: max-height 1s
    //transform-origin: top
    //transition: transform 0.5s ease
    table.table
      .material-icons
        font-size: 14px
        margin-top: 2px
      thead
        border-top: none
        border-bottom: none
        tr
          border: none
          th
            border: none
            padding: 4px
            font-size: 9px
            font-weight: normal
      tbody
        border-top: none
        tr
          td
            padding: 7px 4px
            font-size: 12px


.bg-transmittal-title
  background-color: #f5f5f5
