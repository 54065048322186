.mass-allocate-requests
  table.table
    thead
      th
        font-size: 14px
        padding: 5px

        .order
          float: right
        .react-bootstrap-table-sort-order
          float: right

    tbody
      td
        font-size: 14px
        padding: 5px
