.inventory
  //display: flex
  border-bottom: 1px solid #f8f7f7
  &:last-child
    border-bottom: 0
  padding: 15px 5px
  margin-bottom: 10px
  h4
    margin-top: 3px
    margin-bottom: 10px
  .text-detail-user
    display: flex
    flex: 1
    min-height: 80px
    .detail-info
      align-self: flex-end
      font-size: 80%

  .btn-group
    margin-top: 10px

.label-units 
  height: 25px !important
  min-width: 100px
  max-width: 100px
  border-radius: 100px
  width: 100%
  display: flex
  justify-content: center
  align-items: center
  white-space: nowrap
  color: white
  font-weight: 600

.label-container 
  height: 25px !important
  min-width: 100px
  border-radius: 100px
  width: 100%
  display: flex
  justify-content: center
  align-items: center
  white-space: nowrap
  color: white
  font-weight: 600

.label-container-open
  background: #E08406

.label-container-check
  background: #E0BE04

.label-container-pending
  background: #2DBDFD

.label-container-empty
  background: #00AA51

.rdt_TableHeadRow
  .jGjcID
    background: #3279B7

@media (max-width: 768px)
  .inventory
    .text-detail-user
      p
        margin-top: 10px

// create view
.number-question
  display: flex
  align-items: center
  span
    margin-left: 5px

