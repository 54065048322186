.preview-files
  display: flex
  //flex-wrap: wrap
  .add-file
    font-size: 13px
    position: relative
    display: flex
    align-items: center
    justify-content: center
    flex-direction: column
    text-align: center
    margin: 5px
    height: 90px
    width: 100%
    background-color: #f3f4f6
    border: 1px dashed #979797
    cursor: pointer
  .file
    position: relative
    display: flex
    align-items: center
    justify-content: center
    flex-direction: column
    margin: 5px
    height: 90px
    width: 90px
    border: 1px #CCCCCC solid
    .tooltip
      min-width: 200px
      max-width: 100%
    img
      font-family: "object-fit:cover", serif
      object-fit: cover
      height: 90px
      width: 90px
    i
      width: 14px
      height: 14px
      background: #FFFFFF
      border-radius: 50%
      position: absolute
      top: -7px
      right: -6px
      z-index: 1
      font-size: 16px
    .name-file
      color: #696969
      padding: 6px 0 8px 0
      font-size: 10px
      max-width: 70px
      white-space: nowrap
      overflow: hidden
      text-overflow: ellipsis
    .icon
      margin-top: 12px
      height: 40px !important
      width: 34px
      background-size: contain
      background-repeat: no-repeat
      &.type-excel
        background-image: url("/static/images/files/excel.png")
      &.type-word
        background-image: url("/static/images/files/word.png")
      &.type-pdf
        background-image: url("/static/images/files/pdf.png")
      &.type-file
        background-image: url("/static/images/files/file.png")
