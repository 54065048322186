body
  font-family: 'Source Sans Pro',sans-serif !important
input,
button,
select,
textarea
  font-family: 'Source Sans Pro',sans-serif !important

.modal-body
  padding: 10px

@media (min-width: 768px)
  .modal-body
    padding: 20px

.breadcrumb
  li
    color: #333
    .breadcrumb-section
      color: #000
    .breadcrumb-sub-section
      color: #0073b7
    .active
      color: #000
      font-weight: bold
    &:before
      color: #000
      font-weight: bold

.nav-tabs
  margin: 0 -5px 20px -5px
  li
    boder: 0
    // border: 1px solid #ddd
    // background-color: #000
    font-weight: bold
    &.active > a
      color: #000
      
      boder: 0
      margin: 0
      // background-color: #3c8dbc
      // color: #fff

      &:hover
        boder: 0
        margin: 0

      &:focus
        boder: 0
        margin: 0
      

.line-through
  text-decoration: line-through

.width-10
  width: 10px

.max-width-300
  max-width: 300px

.middle
  vertical-align: middle !important

.width-20
  width: 20% !important

.width-25
  width: 25% !important

.b-t-0
  border-top: 0 !important

.m-0
  margin: 0 !important

.m-t-10
  margin-top: 10px !important

.m-b-10
  margin-bottom: 10px !important

.m-t-20
  margin-top: 20px !important

.m-b-20
  margin-bottom: 20px !important

.middle-center
  vertical-align: middle !important
  text-align: center !important

.not-allowed
  cursor: not-allowed

.pointer
  cursor: pointer

.zoom-in
  cursor: zoom-in

.zoom-out
  cursor: zoom-out

.no-striped
  background-color: #ffffff !important
  &:hover
    background-color: #ffffff !important

.has-error
  > .input-group-addon
    border-color: $redErros
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075)

.table-sm
  tbody
    tr
      td
        font-size: 12px

//.table-responsive
//  .open
//    position: absolute
//    min-width: 200px
//    max-width: 200px
//    z-index: 999

.dropdown-menu
  li
    .check-mark
      margin-right: 0 !important
      right: 10px !important
      top: 8px !important
    a
      padding: 5px 8px


.input-group-addon
  &.input-group-primary
    background-color: $primary
    color: $white
    border-color: $primary

.deleted-item
  transition: background-color 500ms
  background-color: transparentize($redErros, 0.6) !important
  color: $white

.editing-item
  transition: background-color 500ms
  background-color: transparentize($primary, 0.4) !important
  color: $white

@-webkit-keyframes info-fade
  0%
    background: #d9edf7
  100%
    background: none

.no-background-hover:hover
  background-color: transparent !important

@keyframes info-fade
  0%
    background: #d9edf7
  100%
    background: none

.highlight-info
  animation: info-fade 2s ease-in 1

.text-ellipsis
  overflow: hidden
  text-overflow: ellipsis
  white-space: nowrap

.chosen-container
  width: 100% !important
  height: 32px

.form-group
  label
    color: #2372bb

.form-group-no-margin
  .form-group
    margin: 0 !important
  .help-block
    margin: 0 !important

.icheck-in-checkbox
  margin-top: -4px
  margin-right: 5px

// miniature in detaill inventory
.images-25
  img
    image-orientation: from-image
    height: 25px
    width: 25px
    object-fit: cover
    padding: 0

// row with right border
.right-border
  div
    border-right: 1px solid #cccccc4d
    &:last-child
      border-right: 0

@media (max-width: 768px)
  .right-border
    .no-right-border-mobile
      border-right: 0

@media (max-width: 768px)
  .vertical-line-mobile
    div
      border-bottom: 1px solid #ddd
      padding-bottom: 5px
      padding-top: 5px
      &:last-child
        border-bottom: none

.btn-filter
  &.btn-default
    &:focus
      outline: 1px auto -webkit-focus-ring-color !important
      outline-color: #3c8dbc !important
      border-color: #d2d6de
    .filter-option
      color: #777 !important
      background: #FFF !important

.open
  .btn-filter
    border-color: #3c8dbc
    box-shadow: none


#center-message
  background-color: rgba(50, 50, 50, 0.8)
  color: rgb(250, 250, 250)
  position: fixed
  display: none
  width: auto
  left: 50%
  transform: translateX(-50%)
  height: auto
  top: 70vh
  box-sizing: border-box
  text-align: center
  padding: 10px 50px
  z-index: 9999

.background-transition
  transition: background-color 400ms, color 0ms
  >tr.bg-aqua-active
    color: #fff !important
    >td
      color: #fff !important
      .text-muted
        color: #fff !important
  >tr.bg-red-active
    color: #fff !important
    >td
      color: #fff !important
      .text-muted
        color: #fff !important

.dropdown-menu-scrollable
  height: auto
  max-height: 170px
  overflow-x: hidden

.change-image-wrapper
  position: relative
  text-align: center
  padding: 10px
  &:hover
    .text-layer
      visibility: visible
      opacity: 1
      .text
        transform: translateY(0)
  .text-layer
    position: absolute
    top: 0
    bottom: 0
    left: 0
    right: 0
    background: rgba(0, 0, 0, 0.5)
    color: #fff
    visibility: hidden
    opacity: 0
    display: flex
    align-items: center
    justify-content: center

    /* transition effect. not necessary */
    transition: opacity .2s, visibility .2s

    border-radius: 5px
    //border: #0a0a0a solid 1px
    .text
      transition: .2s
      transform: translateY(2em)

.text-underline
  text-decoration: underline

.table-sm
  div
    font-size: 12px
  thead
    tr
      th
        font-size: 12px
        padding: 4px 2px
  tbody
    tr
      td
        padding: 10px 3px
        font-size: 12px

.table-xs
  div
    font-size: 11px
  thead
    tr
      th
        //&:last-of-type
        //  padding: 4px 5px 4px 5px
        font-size: 11px
        padding: 4px 5px
  tbody
    tr
      td
        //&:last-of-type
        //  padding: 7px 5px 7px 5px
        padding: 8px 5px
        font-size: 11px
        line-height: 18px

.select-sm
  height: 30px
  padding-left: 5px
  padding-top: .375rem
  padding-bottom: .375rem
  font-size: 75%

.input-sm
  padding: 5px

.input-xs
  height: 30px
  padding: 5px
  font-size: 12px
  line-height: 1.5
  border-radius: 3px

.font-10
  font-size: 10px 

.font-12
  font-size: 12px

.font-14
  font-size: 14px

.datepicker
  font-size: 12px

.form-control
  padding-right: 6px



// height: 30px;
// padding: 5px 10px;
// font-size: 12px;
// line-height: 1.5;
// border-radius: 3px;

// height: 46px;
// padding: 10px 16px;
// font-size: 18px;
// line-height: 1.3333333;
// border-radius: 6px;

.text-wrap
  white-space: pre-wrap

.flex
  display: flex

.flex-row
  flex-direction: row

.flex-column
  flex-direction: column

.flex-1
  flex: 1

.p-3
  padding: 3px

.flex-row-item
  flex: 1 1 0

.red
  color: red

.green
  color: green

.datepicker
  z-index: 6000 !important

.justify-content-between
  justify-content: space-between

.align-items-center
  align-items: center

.bordered
  border: 1px solid #d2d6de !important

.font-normal
  font-weight: normal

.font-bold
  font-weight: bold

.font-32
  font-size: 32px

.fa-container-red 
  margin-right: 3px
  transform: translateY(2px)
  width: 18px !important
  height: 14px
  background-image: url('../../images/container_ico.svg')
  background-size: contain
  background-repeat: no-repeat
  color: red
    

@media (max-width: 768px)
  .xs-center-text
    text-align: center

@media (max-width: 1200px)
  .lg-tm-10
    margin-top: 10px
