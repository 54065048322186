.multi-upload-progress-bar
  margin: 0
  height: 2px
  overflow: hidden
  background-color: #f5f5f5
  position: absolute
  bottom: 0
  z-index: 10
  width: 100%
  .multi-progress
    height: 100%
    transition: width 1s
    background-color: #0799d3

.multi-upload
  display: flex
  flex-wrap: wrap
  &.list-mode
    flex-direction: column
    .item
      height: 80px
    .image-item
      width: 60px
    .add-item
      margin: 20px
      flex: 1
      padding-top: 30px
      padding-bottom: 30px
      width: auto
      height: 80px
    .multi-upload-progress-bar
      position: relative
      margin: 10px 0
    .border-bottom
      .border:not(:last-child)
        border-bottom: 1px solid #eee
  .add-item
    text-align: center
    font-size: 11px
    margin: 10px
    padding-top: 14px
    border: 1px dashed #CCC
    width: 80px
    height: 80px
    color: #6d6d6d
    background-color: #f1f1f1
    &:first-child
      margin: 10px 10px 10px 0

  .item-container
    margin: 10px
    border: 1px solid #f1f1f1
    width: 80px
    height: 80px
    position: relative

    .delete-button
      height: 0
      position: absolute
      top: -10px
      left: 70px
      z-index: 10
      .fa
        background-color: #ffffff
        font-size: 18px
    .text-description
      font-size: 10px
      text-overflow: ellipsis
      overflow: hidden
      white-space: nowrap
      color: #4a4a4a
      width: 100%
      height: auto
      padding: 0 5px
      margin: 0
  .item
    display: flex
    justify-content: center
    align-items: center
    height: 100%
    flex-direction: column

  .image-item
    transition: opacity 500ms ease-in-out
    image-orientation: from-image
    height: 80px
    width: 80px
    object-fit: contain
    padding: 1px

.icon-file
  background-size: contain
  background-position: top
  background-repeat: no-repeat
  transition: opacity 500ms ease-in-out
  width: 60% !important
  height: 60% !important
  &.word
    background-image: url('/images/icon-doc.svg') !important
  &.excel
    background-image: url('/images/icon-xls.svg') !important
  &.pdf
    background-image: url('/images/icon-pdf.svg') !important
  &.file 
    background-image: url('/images/icon-file.svg') !important

.multi-upload-errors
  .add-item
    border: 1px dashed #dd4b39
    color: #dd4b39
    background-color: #fff1ef
