.stock-table
  table.table
    border: none
    thead
      th
        background-color: #337ab7
        border: none
        color: #fff
        font-size: 14px
        padding: 20px 8px
        .react-bootstrap-table-sort-order
          float: right
        .order
          float: right

    tbody
      tr
        &:nth-of-type(odd)
          background-color: #f9f9f9

        &:hover
          background-color: #f5f5f5
      td
        border: none
        color: #777
        font-size: 14px
        padding: 8px

        &.text-primary
          color: #337ab7
          font-weight: bold
