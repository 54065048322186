.autocomplete
  /* the container must be positioned relative:*/
  position: relative
  display: inline-block
  width: 100%

.items
  position: absolute
  border: 1px solid #d4d4d4
  border-bottom: none
  border-top: none
  overflow-y: auto
  max-height: 300px
  min-width: 200px
  z-index: 99

  /* position the autocomplete items to be the same width as the container:
  top: 100%
  left: 0
  right: 0

  .item
    padding: 5px
    font-size: 12px
    cursor: pointer
    background-color: #fff
    border-bottom: 1px solid #d4d4d4
    transition: background-color 200ms ease
    &:hover
      /* when hovering an item:
      color: #ffffff
      background-color: #2372bb
